import { GridFlex } from '@stigg-components';
import styled from 'styled-components/macro';
import { Clock } from 'react-feather';
import { CodeOff } from '@mui/icons-material';

export const ImportContainer = styled(GridFlex.RowCenter)<{ $inProgressTask: boolean }>`
  border-radius: 10px;
  height: 157px;
  padding: 24px;
  background-color: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.darkBackground};

  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorderLight};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  margin-top: 24px;
  cursor: ${({ $inProgressTask }) => ($inProgressTask ? 'default' : 'pointer')};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme, $inProgressTask }) =>
      $inProgressTask ? 'none' : theme.itamar.palette.primary.outlinedHoverBackground};
  }
`;

export const ImportStripeImage = styled.img`
  height: 100px;
  width: 150px;
  margin-left: 10px;
`;

export const ClockIcon = styled(Clock)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

export const CodeOffIcon = styled(CodeOff)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;
