import { t } from 'i18next';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { Box, GridFlex, Text, Button, InformationTooltip } from '@stigg-components';
import { CustomerSubscriptionResourceFragment, EntitlementDataFragment } from '@stigg-types/apiTypes';
import { FluctuatingUsageGraphWrapper } from './FluctuatingUsageGraphWrapper';
import { getUsageTitle, getResetPeriodConfig } from './entitlementUsage.utils';
import { ReportUsageDrawer } from './ReportUsageDrawer';
import { EntitlementUsageFilters } from './EntitlementUsageFilters';
import { getFeatureDisplayName } from '../../../../features/utils/getFeatureDisplayName';

const ExpandedRowBox = styled(Box)`
  cursor: default;
  background-color: ${({ theme }) =>
    theme.isLightTheme ? ' #fafafa' : theme.itamar.palette.background.lightBackground};
  padding: ${(props) => props.theme.spacing(4)};
`;

const GraphArea = styled(Box)`
  height: 160px;
`;

const ReportUsageButton = ({ disableReportUsage, onClick }: { disableReportUsage: boolean; onClick: () => void }) => {
  return (
    <InformationTooltip
      arrow
      $maxWidth={240}
      title={disableReportUsage ? <Text.Sub2>{t('customers.addUsageRecord.disabled')}</Text.Sub2> : ''}
      placement="top">
      <span>
        <Button color="primary" $outlined disabled={disableReportUsage} onClick={onClick}>
          {t('customers.entitlementUsageSectionAddUsage')}
        </Button>
      </span>
    </InformationTooltip>
  );
};

export const ExpandedEntitlementUsage = ({
  customerRefId,
  entity,
  selectedResource,
}: {
  customerRefId: string;
  entity: EntitlementDataFragment;
  selectedResource?: CustomerSubscriptionResourceFragment;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [groupBy, setGroupBy] = useState<string | null>(null);
  const featureRefId = entity.feature?.refId as string;
  const { meterId } = entity;
  const resourceRefId = selectedResource?.resource?.resourceId;

  return (
    <>
      <ExpandedRowBox>
        <GridFlex.RowSpaceBetween alignItems="flex-start" mb={4}>
          <GridFlex.Item>
            <Text.B2>{entity.feature ? getFeatureDisplayName(entity.feature) : ''}</Text.B2>
            <Text.Sub2 gutterBottom>{getUsageTitle(entity.resetPeriod)}</Text.Sub2>
          </GridFlex.Item>
          <GridFlex.RowSpaceBetween item gap={4}>
            <EntitlementUsageFilters
              meterId={meterId}
              featureId={entity.feature?.id}
              customerRefId={customerRefId}
              resourceRefId={resourceRefId}
              selectedGroup={groupBy}
              setSelectedGroup={setGroupBy}
            />
            <ReportUsageButton disableReportUsage={!!entity.meterId} onClick={() => setIsDrawerOpen(true)} />
          </GridFlex.RowSpaceBetween>
        </GridFlex.RowSpaceBetween>
        <GraphArea>
          <FluctuatingUsageGraphWrapper
            featureRefId={featureRefId}
            customerRefId={customerRefId}
            resourceRefId={resourceRefId}
            resetPeriod={entity.resetPeriod}
            usageLimit={entity.usageLimit}
            resetPeriodConfiguration={getResetPeriodConfig(entity.resetPeriodConfiguration)}
            groupBy={groupBy ? [groupBy] : undefined}
          />
        </GraphArea>
      </ExpandedRowBox>
      <ReportUsageDrawer
        customerRefId={customerRefId}
        entitlement={entity}
        subscription={selectedResource}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
