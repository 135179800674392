import styled, { css } from 'styled-components/macro';
import { GridFlex } from '.';

export const FULL_SCREEN_DIALOG_TITLE_HEIGHT = 64;

export const FullScreenDialogContentTitleContainer = styled(GridFlex.RowCenter)<{ $withBackground?: boolean }>`
  width: 100%;
  height: ${FULL_SCREEN_DIALOG_TITLE_HEIGHT}px;
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  border-bottom: ${({ theme }) => `1px solid ${theme.itamar.palette.action.disabledBackground}`};

  ${({ theme, $withBackground }) => {
    if (theme.isLightTheme) {
      return $withBackground && `background-color: ${theme.itamar.palette.grey[50]};`;
    }
    return `background-color: ${theme.itamar.palette.background.darkBackground2};`;
  }}
`;

export const FullScreenDialogContentContainer = styled(GridFlex.Column)<{
  $disableContentScroll?: boolean;
  $withBackground?: boolean;
}>`
  ${({ $disableContentScroll }) =>
    !$disableContentScroll &&
    css`
      overflow: auto;
    `}
  height: calc(100% - ${FULL_SCREEN_DIALOG_TITLE_HEIGHT}px);
  ${({ theme, $withBackground }) => {
    if (theme.isLightTheme) {
      return $withBackground && `background-color: ${theme.itamar.palette.grey[25]};`;
    }

    return `background-color: ${theme.itamar.palette.background.paper};`;
  }}
`;
