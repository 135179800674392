import React, { useState } from 'react';
import { ItamarTheme as Theme } from '@stigg-theme';
// eslint-disable-next-line no-restricted-imports
import { PopoverOrigin } from '@mui/material';
import { Box } from '@stigg-components';
import * as S from './MenuDropdown.style';

export function MenuDropdown({
  dropdownTrigger,
  menuItems,
  isMenuWidthBasedOnTriggerWidth,
  disabled,
  anchorOrigin,
  transformOrigin,
  backgroundColor,
  borderColor,
  menuMarginTop,
  menuMarginLeft,
  minWidth = 150,
  withFullWidthContainer = true,
}: {
  backgroundColor?: (theme: Theme) => string;
  borderColor?: string;
  dropdownTrigger: JSX.Element;
  menuItems: JSX.Element[] | JSX.Element;
  isMenuWidthBasedOnTriggerWidth?: boolean;
  disabled?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  menuMarginTop?: string;
  menuMarginLeft?: string;
  minWidth?: number;
  withFullWidthContainer?: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <S.MenuHolder onClick={(e) => e.stopPropagation()} $fullWidth={withFullWidthContainer}>
      <Box onClick={handleClick} aria-hidden="true">
        {dropdownTrigger}
      </Box>
      <S.Menu
        PaperProps={{
          sx: {
            minWidth: isMenuWidthBasedOnTriggerWidth ? anchorEl?.clientWidth : minWidth,
            backgroundColor:
              backgroundColor ||
              ((theme: Theme) => (theme.isLightTheme ? undefined : theme.itamar.palette.background.darkBackground)),
            border: (theme: Theme) => {
              const color = theme.isLightTheme ? borderColor : borderColor || theme.itamar.border.borderColor;

              return color ? `1px solid ${color}` : 'none';
            },
            marginTop: menuMarginTop,
            marginLeft: menuMarginLeft,
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'right',
          }
        }
        open={!!anchorEl}
        onClick={handleClose}
        onClose={handleClose}>
        {menuItems}
      </S.Menu>
    </S.MenuHolder>
  );
}
